<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.signature') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="onValidate">
        <ODNForm>
          <ODNFormField
            :error="
              isSubmitted && errors.lastname ? $t('errors.required') : null
            "
            :label="$t('fields.lastname.label')"
            label-position="floating"
          >
            <ion-input v-model="form.lastname" type="text"></ion-input>
          </ODNFormField>
          <ODNFormField
            :error="
              isSubmitted && errors.firstname ? $t('errors.required') : null
            "
            :label="$t('fields.firstname.label')"
            label-position="floating"
          >
            <ion-input v-model="form.firstname" type="text"></ion-input>
          </ODNFormField>
          <ODNFormField>
            <template #alt-label>
              {{ $t('fields.signature.label') }}
            </template>
            <template #alt-content>
              <canvas
                ref="pad"
                width="300"
                height="160"
                class="odn-signature-pad"
              ></canvas>
            </template>
          </ODNFormField>
          <div class="odn-mat-16">
            <ion-button
              type="submit"
              color="primary"
              expand="block"
              @click="onValidate"
            >
              {{ $t('buttons.validate') }}
              <ion-icon slot="end" :icon="icons.checkmark"></ion-icon>
            </ion-button>
          </div>
        </ODNForm>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  modalController,
  toastController,
} from '@ionic/vue';
import { close, checkmark } from 'ionicons/icons';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';

import SignaturePad from 'signature_pad';

export default {
  name: 'ModalSignature',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonInput,
    ODNForm,
    ODNFormField,
  },
  data() {
    return {
      form: {
        lastname: null,
        firstname: null,
      },
      signaturePad: null,
      isSubmitted: false,
      icons: {
        close,
        checkmark,
      },
    };
  },
  computed: {
    errors() {
      return {
        lastname: !this.form.lastname,
        firstname: !this.form.firstname,
      };
    },
  },
  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.pad, {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      penColor: 'rgb(0, 0, 0)',
    });
  },
  unmounted() {
    this.signaturePad.off();
  },
  methods: {
    async onValidate() {
      this.isSubmitted = true;

      if (this.signaturePad.isEmpty()) {
        const toast = await toastController.create({
          message: this.$t('fields.signature.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
        return;
      }

      const hasErrors = Object.values(this.errors).some((e) => e);

      if (hasErrors) return;

      const picture = this.signaturePad.toDataURL();
      modalController.dismiss({
        picture,
        ...this.form,
      });
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>

<style lang="scss">
.odn-signature-pad {
  margin: 0 auto;
  display: block;
  border-radius: 8px;
}
</style>

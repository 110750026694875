<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ form.id ? $t('modals.edit') : $t('modals.add') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="onSave">
        <ODNForm>
          <ODNFormField
            :error="
              isSubmitted && errors.actionTypeId ? $t('errors.required') : null
            "
            :label="$t('fields.actionType.label')"
          >
            <ion-select
              v-model="form.actionTypeId"
              :placeholder="$t('fields.actionType.placeholder')"
              :cancelText="$t('buttons.cancel')"
              :okText="$t('buttons.validate')"
            >
              <ion-select-option
                v-for="actionType in offline
                  ? getOptionSet('actionTypes').items
                  : actionTypes"
                :key="actionType.id"
                :value="actionType.id"
              >
                {{ $t(`actionTypes.${actionType.name}`) }}
              </ion-select-option>
            </ion-select>
          </ODNFormField>
          <ODNFormField
            :error="isSubmitted && errors.notes ? $t('errors.required') : null"
            :label="$t('fields.notes.label')"
            label-position="floating"
          >
            <ion-input v-model="form.notes" type="text"></ion-input>
          </ODNFormField>
          <ODNUploadImage v-model="ticketFile" :submitted="errors.ticketFile" />
          <ODNFormField>
            <template #alt-label>
              {{ $t('fields.signature.label') }}
            </template>
            <template #alt-content>
              <div class="odn-pay-4" v-if="signature.picture">
                <img
                  :src="signature.picture"
                  :alt="$t('fields.signature.label')"
                />
                <div
                  v-if="signature.lastname || signature.firstname"
                  class="ion-text-center"
                >
                  {{ signature.lastname }}
                  {{ signature.firstname }}
                </div>
              </div>
              <ion-button
                color="light"
                expand="block"
                @click="onModalSignature"
              >
                <ion-icon slot="start" :icon="icons.pencilOutline"></ion-icon>
                {{ $t('buttons.sign') }}
              </ion-button>
            </template>
          </ODNFormField>
          <div class="odn-mat-4">
            <ODNTimer
              v-model="form.duration"
              :is-full="true"
              :is-outline="true"
              :is-hidden="true"
            />
          </div>
          <div class="odn-mat-16">
            <ion-button
              v-if="offline"
              type="submit"
              color="primary"
              :disabled="saving"
              expand="block"
            >
              {{ $t('buttons.save') }}
              <ODNSpinner v-if="saving" slot="end" />
              <ion-icon v-else slot="end" :icon="icons.save"></ion-icon>
            </ion-button>
            <ion-button
              v-else
              type="submit"
              color="primary"
              :disabled="saving"
              expand="block"
            >
              {{ $t('buttons.validate') }}
              <ODNSpinner v-if="saving" slot="end" />
              <ion-icon v-else slot="end" :icon="icons.checkmark"></ion-icon>
            </ion-button>
          </div>
        </ODNForm>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  modalController,
  toastController,
} from '@ionic/vue';
import { close, pencilOutline, checkmark, save } from 'ionicons/icons';

import { dataUrlToFile } from '@s/helper';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';
import ODNUploadImage from '@c/odn-upload-image.vue';
import ODNTimer from '@c/odn-timer';
import ODNSpinner from '@c/odn-spinner.vue';

import ODNModalSignature from '@m/odn-modal-signature.vue';

import APIService from '@s/api.service';

export default {
  name: 'ModalFile',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonInput,
    IonSelect,
    IonSelectOption,
    ODNForm,
    ODNFormField,
    ODNTimer,
    ODNUploadImage,
    ODNSpinner,
  },
  props: {
    ticketId: {
      type: Number,
      default: null,
    },
    actiontId: {
      type: Number,
      default: null,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        id: null,
        ticketId: this.ticketId,
        actionTypeId: null,
        notes: null,
        dueDate: null,
        duration: 0,
      },
      ticketFile: null,
      signature: {
        lastname: null,
        firstname: null,
        picture: null,
      },
      isSubmitted: false,
      loading: false,
      saving: false,
      actionTypes: [],
      actionTypesLoading: false,
      icons: {
        close,
        pencilOutline,
        checkmark,
        save,
      },
      offlineTicketAction: {
        store: null,
        ticketId: null,
        action: null,
        signature: null,
        upload: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    ...mapState('tickets', {
      ticket: 'selectedTicket',
    }),
    ...mapGetters('offline', ['getOptionSet', 'getSection']),
    errors() {
      const errors = {
        actionTypeId: !this.form.actionTypeId,
      };
      if (this.isNotesRequired) {
        errors['notes'] = !this.form.notes;
      }
      if (this.isImageRequired) {
        errors['ticketFile'] = !this.ticketFile;
      }
      return errors;
    },
    isNotesRequired() {
      // Get specific action types ids
      const specificActionTypeIds = this.actionTypes
        .filter((at) => ['OPENING', 'INTERVENTION_REQUESTED'].includes(at.name))
        .map((at) => at.id);

      // If conditions met (user role and specific action type)
      // Make ticket file required
      return (
        specificActionTypeIds.includes(this.form.actionTypeId) &&
        this.isSubmitted &&
        this.profile.role &&
        this.profile.role.name === 'user'
      );
    },
    isImageRequired() {
      // Get specific action types ids
      const specificActionTypeIds = this.actionTypes
        .filter((at) =>
          ['OPENING', 'CLOSING', 'INTERVENTION_REQUESTED'].includes(at.name)
        )
        .map((at) => at.id);

      // If conditions met (user role and specific action type)
      // Make ticket file required
      return (
        specificActionTypeIds.includes(this.form.actionTypeId) &&
        this.isSubmitted &&
        this.profile.role &&
        this.profile.role.name === 'user'
      );
    },
  },
  created() {
    if (this.actionId) {
      this.fetchAction();
    }
    this.fetchActionTypes();
  },
  methods: {
    ...mapActions('offline', ['saveOfflineTicketAction']),
    async fetchAction() {
      this.loading = true;
      try {
        const response = (await APIService.get(`/actions/${this.actionId}`))
          .data;
        this.form.id = response.id;
        this.form.actionTypeId = response.actionType.id;
        this.form.notes = response.notes;
        this.form.dueDate = response.dueDate;
        this.form.duration = response.duration;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.action.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchActionTypes() {
      this.actionTypesLoading = true;
      try {
        if (!this.offline) {
          this.actionTypes = (await APIService.get(`/action-types`)).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.actionTypes.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.actionTypesLoading = false;
      }
    },
    async onModalSignature() {
      const modal = await modalController.create({
        component: ODNModalSignature,
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.signature = result;
      }
    },
    async saveSignature(actionId) {
      const { lastname, firstname, picture } = this.signature;
      try {
        const randomNumber = new Date().getTime();
        const file = await dataUrlToFile(
          picture,
          `signature-action-${this.actionId}-${randomNumber}.png`,
          {
            type: `image/png`,
          }
        );

        const formData = new FormData();
        formData.append('file', file);

        if (lastname) {
          formData.append('lastname', lastname);
        }

        if (firstname) {
          formData.append('firstname', lastname);
        }

        return await APIService.upload(
          `/tickets/${this.ticketId}/actions/${actionId}/signature`,
          formData
        );
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.actionSignature.post.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      }
    },
    async onSave() {
      this.isSubmitted = true;

      const hasErrors = Object.values(this.errors).some((e) => e);

      if (hasErrors) return;

      this.saving = true;

      this.offlineTicketAction.store = {
        id: this.store.id,
        store: this.store.name,
        retailer: this.store.retailer.name,
      };

      this.offlineTicketAction.ticketId = this.ticketId;

      if (this.actionId) {
        try {
          await APIService.put(
            `/tickets/${this.ticketId}/actions/${this.actionId}`,
            this.form
          );
          const toast = await toastController.create({
            message: this.$t('messages.action.put.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();
          modalController.dismiss(true);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.action.put.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      } else {
        try {
          let id;

          // Création de l'action
          if (this.offline) {
            this.offlineTicketAction.action = this.form;
          } else {
            id = (
              await APIService.post(
                `/tickets/${this.ticketId}/actions`,
                this.form
              )
            ).data.id;
          }

          // Signature
          if (this.signature.picture) {
            if (this.offline) {
              this.offlineTicketAction.signature = this.signature;
            } else {
              await this.saveSignature(id);
            }
          }

          // Upload de la photo
          if (this.ticketFile) {
            if (this.offline) {
              this.offlineTicketAction.upload = {
                file: this.ticketFile.file,
                type: this.ticketFile.file.type,
              };
            } else {
              const formData = new FormData();
              formData.append('actionId', id);
              formData.append('file', this.ticketFile.file);
              if (this.form.notes) {
                formData.append('notes', this.form.notes);
              }
              await APIService.upload(
                `/tickets/${this.ticketId}/files`,
                formData
              );
            }
          }

          if (this.offline) {
            await this.saveOfflineTicketAction(this.offlineTicketAction);
          }

          const toast = await toastController.create({
            message: this.$t('messages.action.post.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();

          modalController.dismiss(true);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.action.post.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      }
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>
